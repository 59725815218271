import React, { useEffect } from "react";
import img1 from "../../Assets/img/tbg.png";
import img2 from "../../Assets/img/darknight.jpg";
import "../About/About.css";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
    });
    Aos.refresh();
  }, []);

  return (
    <body>
      <section
        class="review-section spad sp-pad set-bg"
        data-setbg="srfolder/img/review-bg.jpg"
      >
        <div class="container-fluid p-0 review-warp">
          <div class="row">
            <div class="col-xl-4">
              <span class="sp-sub-title">GemTexch</span>
              <h3 class="sp-title">What Others say</h3>
            </div>
            <div class="col-xl-7 offset-xl-1">
              <div class="review-slider owl-carousel">
                <div class="rs-item">
                  <div class="qute">“</div>
                  <p>GemTexch is the powerhouse Telos NFTs</p>
                  <figure class="client-pic">
                    <img src={img1} alt="#" />
                  </figure>
                  <a href="https://twitter.com/TheBigGooey" target={"_blank"}>
                    <h4>TBG, Telos NFT Curator</h4>
                  </a>
                </div>
                <div class="rs-item">
                  <div class="qute">“</div>
                  <p>GemTexch does it the right way</p>
                  <figure class="client-pic">
                    <img src={img2} alt="#" />
                  </figure>
                  <a
                    href="https://twitter.com/DarkniteCrypto"
                    target={"_blank"}
                  >
                    <h4>Darknight, BA4C Project Manager</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*====== Javascripts & Jquery ======*/}
      <script src="srfolder/js/jquery-3.2.1.min.js"></script>
      <script src="srfolder/js/bootstrap.min.js"></script>
      <script src="srfolder/js/owl.carousel.min.js"></script>
      <script src="srfolder/js/mixitup.min.js"></script>
      <script src="srfolder/js/circle-progress.min.js"></script>
      <script src="srfolder/js/main.js"></script>
    </body>
  );
};

export default About;
