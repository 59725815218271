import React from "react";

import Traits from "./Collections/Collections";
const Services = () => {
  return (
    <div id="track">
      <section class="services-section spad">
        <div class="container-fluid services-warp">
          <div class="services-text">
            <div class="container p-0">
              <span class="sp-sub-title">GemTexch is Building</span>
              <h3 class="sp-title">What we built so far</h3>
              <p>
                GemTexch has been focused in it's sole mandate of building the
                tools for a fantastic Web3 ecosystem powered by the Telos
                Blockchain. Right now this includes some tools for the NFT
                ecosystem listed here:
              </p>
            </div>
          </div>
          <div class="container p-0">
            <div class="row">
              <div class="col-xl-8 offset-xl-4">
                <div class="row">
                  <div class="col-md-6 service">
                    <a href="https://rank.gemtexch.com" target="_blank">
                      <div class="icon-box">
                        <i class="flaticon-video-camera"></i>
                        <h4>Rarity Platform </h4>
                        <p>
                          We launched{" "}
                          <a
                            href="https://rank.gemtexch.com/"
                            target={"_blank"}
                          >
                            GemRank
                          </a>
                          , the first comprehensive platform where users can
                          peruse the rarity of all top and many upcoming NFT
                          collections. GemRank sharp UI emcompasses a chart of
                          real time performance of top NFT projects on Telos.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-6 service">
                    <a href="https://ogducks.gemtexch.com" target="_blank">
                      <div class="icon-box">
                        <i class="flaticon-photo-camera"></i>
                        <h4>NFT Tokengating</h4>
                        <p>
                          TokenGate your discord server with GEMTY [the bot].
                          GEMTY gatekeeps exclusive channels in your server to
                          be accessed by holders of your NFT collection. GEMTY
                          is trusted by the Telos NFT Hub on discord to
                          tokengating. Want GEMTY in your discord?{" "}
                          <a href="rank.gemtexch.com" target={"_blank"}>
                            Click here
                          </a>
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-6 service">
                    <a href="https://ogducks.gemtexch.com" target="_blank">
                      <div class="icon-box">
                        <i class="flaticon-safebox"></i>
                        <h4>Launchpad</h4>
                        <p>
                          GemTexch has served as a launchpad for serveral NFT
                          collections on Telos. We have built a sense of tested
                          trust in the Telos Ecosystem letting community members
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-6 service">
                    <a href="https://ogducks.gemtexch.com" target="_blank">
                      <div class="icon-box">
                        <i class="flaticon-idea"></i>
                        <h4>Original GemDucks</h4>
                        <p>
                          The{" "}
                          <a href="ogducks.gemtexch.com" target={"_blank"}>
                            OG Ducks
                          </a>{" "}
                          remain the only NFT collection owned and operated by
                          GemTexch, holders stand as patrons supporting the
                          movement of innovation and serving as the base
                          community of GemTexch. In turn, GemTexch is commited
                          with providing utility and incentives
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <p>
            We take pride in our tools. That being said, we have only scratched
            the tip of the iceberg and still have so much instored.:
          </p>
          <a href="#roadmap" class="site-btn">
            See Roadmap
          </a>
        </div>
      </section>
      <section class="intro-section sp-pad spad" id="services">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-4 intro-text">
              <span class="sp-sub-title">NFT studio</span>
              <h3 class="sp-title">Need help for your NFT collection?</h3>
              <p>
                You've got a killer NFT collection you intend to drop. You have
                spent so much time and energy planning it out, working on the
                arts and building engagement. Let us do the rest, from
                generating metadata (one of ones included), deployng a custom
                smart contract and setting up a powerful Minting Dapp just the
                way you want it.
              </p>
              <a href="getquote.html" target={"_blank"} class="site-btn">
                Get Quote
              </a>
            </div>
            <div class="col-xl-7 offset-xl-1">
              <figure class="intro-img mt-5 mt-xl-0">
                <img src="../../Assets/img/intro.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section class="portfolio-section" id="collections">
        <div className="project-heading">SOME PROJECTS WE WORKED WITH</div>
        <Traits />
      </section>
    </div>
  );
};

export default Services;
