import React from "react";
import "../Collections/Collections.css";
import Card from "./TraitsCard/Card";
import ItemsDummy from "../ItemsDummy";

const Collections = () => {
  const traits = ItemsDummy;

  return (
    <div className="traits">
      <div className="traits-container">
        {traits.map((trait) => (
          <Card key={trait.id} trait={trait} />
        ))}
      </div>
    </div>
  );
};

export default Collections;
