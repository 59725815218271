import React from "react";
import "../Footer/Footer.css";

const Footer = () => {
  return (
    <footer class="footer-section spad">
      <div class="container text-center">
        <div class="social">
          <span>GemTexch</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
