import React, { useState } from "react";
import "../Nav/Nav.css";
import { Link } from "react-scroll";
import { FaTimes, FaBars } from "react-icons/fa";
import social1 from "../../Assets/svg icons/Discord.svg";
import social2 from "../../Assets/svg icons/Opensea.svg";
import social3 from "../../Assets/svg icons/Rarible.svg";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const hamburgerOpen = <FaBars className="hamburger" onClick={handleClick} />;

  const hamburgerClose = (
    <FaTimes className="hamburger" onClick={handleClick} />
  );
  return (
    <div className="nav">
      <div className="header">
        <div className="logo">
          <a href="/">GEMTEXCH</a>
        </div>

        <div className="main-menu" onClick={handleClick}>
          {open ? hamburgerClose : hamburgerOpen}
        </div>
      </div>

      <div className={open ? "nav-links active" : "nav-links"}>
        <header class="header-section sp-pad">
          <div class="nav-switch">
            <i class="fa fa-bars"></i>
          </div>
          <nav class="main-menu">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">about us</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default Nav;
