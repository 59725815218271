import React from "react";
import "../Roadmap/Roadmap.css";

const Roadmap = () => {
  return (
    <section class="milestones-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 fact-box">
            <div class="fact-content">
              <i class="flaticon-gamepad"></i>
              <h2>8</h2>
              <p>NFT Projects Launched</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 fact-box">
            <div class="fact-content">
              <i class="flaticon-trophy"></i>
              <h2>7</h2>
              <p>FEATURES WON</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 fact-box">
            <div class="fact-content">
              <i class="flaticon-alarm-clock"></i>
              <h2>1K</h2>
              <p>Total NFTs Minted</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 fact-box">
            <div class="fact-content">
              <i class="flaticon-laptop"></i>
              <h2>9</h2>
              <p>Clients</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
