import React from "react";
import "./Contact.css";
import bg from "../../Assets/img/hero-slider/1.jpg";

const Contact = () => {
  return (
    <section
      class="contact-section set-bg spad"
      id="contact"
      data-setbg="srfolder/img/contact-bg.jpg"
      style={{ backgroundImage: { bg } }}
    >
      <div class="container-fluid contact-warp">
        <div class="contact-text">
          <div class="container p-0">
            <span class="sp-sub-title">Contact</span>
            <h3 class="sp-title">Get in touch</h3>
            <p>
              We are delighted to connect with you on your Web3 Journey. Send a
              message and we'll be in touch within 3 working days.
            </p>

            <ul class="con-info">
              <li>
                <i class="fa fa-envelope"></i>
                code@gemtexch.com
              </li>
              <li>
                <a href="https://twitter.com/gemtexchnology" target={"_blank"}>
                  <i class="fa fa-twitter">gemtexchnology</i>
                </a>
              </li>
              <br />
              <li>
                <a href="https://t.me/gemtexchnology" target={"_blank"}>
                  <i class="fa fa-telegram">gemtexchnology</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="container p-0">
          <div class="row">
            <div class="col-xl-8 offset-xl-4">
              <form class="contact-form">
                <div class="row">
                  <div class="col-md-6">
                    <input type="text" placeholder="Your name" />
                  </div>
                  <div class="col-md-6">
                    <input type="email" placeholder="E-mail" />
                  </div>
                  <div class="col-md-12">
                    <input type="text" placeholder="Subject" />
                    <textarea placeholder="Message"></textarea>
                    <button class="site-btn light">Send</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
