// import NFTContractBuild from 'contracts/NFT.json';
import Web3 from "web3";

let selectedAccount;

let isInitialized = false;

const GEMPAddress = "0xCcE1700A7B116aCE33A968F25ECc526504990424";

const contractAbi = [
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "account", type: "address" },
      { internalType: "uint256", name: "snapshotId", type: "uint256" },
    ],
    name: "balanceOfAt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "swapIn",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },

  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "swapOut",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

let provider = window.ethereum;

let web3 = new Web3(provider);

export const Contract = new web3.eth.Contract(contractAbi, GEMPAddress);

export const init = async () => {
  try {
    provider = window.ethereum;
    provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        selectedAccount = accounts[0];
        console.log(`${selectedAccount} is connected`);
      })
      .catch((err) => {
        console.log(err);
        return;
      });

    window.ethereum.on("accountsChanged", function (accounts) {
      selectedAccount = accounts[0];
      console.log(`Selected account changed to ${selectedAccount}`);
    });

    const currentChainId = window.ethereum.request({
      method: "eth_chainId",
    });

    if (currentChainId !== "0x28") {
      await switchNetwork();
    }

    web3 = new Web3(provider);

    isInitialized = true;
    return isInitialized;
  } catch (error) {
    switch (error.message) {
      case "provider is undefined":
        alert("MetaMask not Detected!");
        break;

      default:
        break;
    }
    console.log(error.message);
  }
};

export const switchNetwork = async () => {
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x28",
        chainName: "Telos EVM Mainnet",
        nativeCurrency: {
          name: "Telos",
          symbol: "TLOS",
          decimals: 18,
        },
        rpcUrls: ["https://mainnet.telos.net/evm"],
        blockExplorerUrls: ["https://teloscan.io"],
      },
    ],
  });
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x28" }],
  });
};

export const SWAP = async (quantity, direction) => {
  if (!isInitialized) {
    await init();
  }
  let hash;
  switch (direction) {
    case "out":
      await Contract.methods
        .swapOut(Web3.utils.toWei(quantity, "ether"))
        .send({
          from: selectedAccount,
          gas: 70000,
        })
        .on("receipt", (receipt) => {
          hash = receipt.transactionHash;
        });
      break;

    default:
      await Contract.methods
        .swapIn()
        .send({
          from: selectedAccount,
          gas: 130000,
          value: Web3.utils.toWei(quantity, "ether"),
        })
        .on("receipt", (receipt) => {
          hash = receipt.transactionHash;
        });
      break;
  }

  return hash;
};

export const addToMetamask = async () => {
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: GEMPAddress,
          symbol: "GEMP",
          decimals: 18,
          image: "http://gemtexch.com/logo.png",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};
