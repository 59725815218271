import img1 from "../../Assets/img/collections/2.jpg";
const ItemsDummy = [
  {
    id: 1,
    image: "/Images/1.jpg",
    chain: "TELOS",
    link: "https://ogducks.gemtexch.com",
    description1: "OG DUCKS",
    description2:
      "The Original Gem Ducks are the pioneering community of the GemDucks.",
  },
  {
    id: 2,
    image: "/Images/2.png",
    chain: "TELOS",
    link: "https://nft.unboundnow.org",
    description1: "UNBOUND NOW NFT",
    description2:
      "Unbound Now is a leading organization in the battle against human trafficking",
  },
  {
    id: 3,
    chain: "ETH",
    image: "/Images/7.jpg",
    link: "https:/ ",
    description1: "SUPOSTU DYNASTY",
    description2:
      "ULTIMATELY,  THE スポーツ SUPOTSU DYNASTY BELIEVE IN BEING A CHAMPION FOR THOSE WHO CAMPAIGN FOR THE WARRIOR WITHIN.",
  },
  {
    id: 4,
    image: "/Images/9.jpeg",
    chain: "ETH, CRONOS, TEZOS",
    link: "http://ba4c.xyz/",
    description1: "BA4C",
    description2:
      "A collective of Web3 Artists & Creatives dedicated to enabling Artists be autonomous throughout the music industry, the blockchain, and into the metaverse.",
  },
  {
    id: 5,
    id: 3,
    chain: "TELOS",
    link: "https://teloskitties.gemtexch.com/",
    image: "/Images/3.jpg",
    description1: "TELOS KITTIES",
    description2:
      "Telos Kitties on the Telos blockchain, which is based on the Erc721 standard, are 100 different Telos Kitties collectibles. ",
  },
  {
    id: 6,
    id: 3,
    chain: "TELOS",
    link: "https://ratbastards.gemtexch.com/",
    image: "/Images/4.jpg",
    description1: "RAT BASTARDS",
    description2:
      "1000 pixelated rats determined to get cheese on the Telos ecosystem",
  },
  {
    id: 7,
    id: 3,
    chain: "TELOS",
    link: "https://toniaswardrobe.gemtexch.com/",
    image: "/Images/5.jpg",
    description1: "TONIA'S WARDROBE",
    description2:
      "Tonia is creating a wardrobe for gotcha dolls online. You will enjoy the different array of wardrobe displayed by these lovely dolls.",
  },
  {
    id: 8,
    id: 3,
    chain: "TELOS",
    link: "https://surrealbutterflies.gemtexch.com/",
    image: "/Images/2.jpg",
    description1: "SURREAL BUTTERFLIES",
    description2:
      "The Surreal Butterflies is an A.I artistic collection of 500 Butterflies which are inspired by famous surrealist painters.",
  },
];

export default ItemsDummy;
