import React from "react";
import "./Card.css";

const Card = ({ trait }) => {
  return (
    <a className="card" href={trait.link} target={"_blank"}>
      <div>
        <div className="image">
          <img width={216} height={112} src={trait.image} alt="" />
        </div>
        <div className="description">
          <h5>{trait.description1}</h5>

          <div className="chain">{trait.chain}</div>
          {trait.description2}
        </div>
      </div>
    </a>
  );
};

export default Card;
