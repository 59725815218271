import React from "react";
import "../Intro/Intro.css";
import bg from "../../Assets/img/hero-slider/1.jpg";
const Intro = () => {
  return (
    <section class="hero-section">
      <div class="hero-slider ">
        <div class="hs-items set-bg sp-pad">
          <span id="home"></span>
          <div class="hs-text">
            <h2 class="hs-title">Welcome to GemTexch</h2>
            <p class="hs-des">
              Building <br />
              Your Web3 Experience The Right Way
            </p>{" "}
            <p style={{ color: "white" }}>
              GemTexch was concepted with the idea of creating a self
              sustainable protocol
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
