import "./App.css";
import About from "./Components/About/About";
import Intro from "./Components/Intro/Intro";
import Nav from "./Components/Nav/Nav";
import Contact from "./Components/Contact/Contact";
import "./Styles/animate.css";
import "./Styles/style.css";
import "./Styles/bootstrap.min.css";
import "./Styles/font-awesome.min.css";
import Footer from "./Components/Footer/Footer";
import Roadmap from "./Components/Roadmap/Roadmap";
import Services from "./Components/Services/Services";
import Token from "./Components/Token/Token";

function App() {
  return (
    <div className="App">
      <Nav />
      <div className="contents">
        <Intro />
        <Services />
        <About />
        <Roadmap />
        <Token />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
