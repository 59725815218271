import React, { useState } from "react";
import "../Token/Token.css";
import "../Token/MintSection.css";
import tlos from "../../Assets/tlos.png";
import gemp from "../../Assets/gemp.png";
import META from "../../Assets/metamask.svg";
import one from "../../Assets/liquidity.png";
import two from "../../Assets/liquidity.png";
import three from "../../Assets/liquidity.png";
import four from "../../Assets/reward.png";
import { addToMetamask, init, SWAP } from "../../Web3Engine";

const Token = () => {
  const [swapOut, setOutputCurrency] = useState(false);
  const [inamount, setSwapIn] = useState("1");
  const [outamount, setSwapOut] = useState("99");
  const [connected, setconnected] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [txid, settxid] = useState("0x0");
  const [tokenmintSuccess, setTokenMintSuccess] = useState(false);

  const changeSwap = () => {
    setOutputCurrency(!swapOut);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleTokenClose = () => {
    setTokenMintSuccess(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Chaning ${name}`);
    console.log(`Value is ${value}`);
    Number.prototype.noExponents = function () {
      var data = String(this).split(/[eE]/);
      if (data.length == 1) return data[0];

      var z = "",
        sign = this < 0 ? "-" : "",
        str = data[0].replace(".", ""),
        mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + "0.";
        while (mag++) z += "0";
        return z + str.replace(/^\-/, "");
      }
      mag -= str.length;
      while (mag--) z += "0";
      return str + z;
    };
    switch (name) {
      case "swapin":
        setSwapIn(value);
        setSwapOut((value * 99).noExponents());
        break;

      default:
        setSwapIn((value / 99).noExponents());
        setSwapOut(value);
        break;
    }
  };

  const swapitem = (logo) => {
    if (logo == gemp) {
      return (
        <span className="swapspan ">
          <input
            type="number"
            name="swapout"
            value={outamount}
            onChange={handleChange}
          />
          <img src={logo} />
        </span>
      );
    } else {
      return (
        <span className="swapspan ">
          <input
            type="number"
            name="swapin"
            value={inamount}
            onChange={handleChange}
          />
          <img src={logo} />
        </span>
      );
    }
  };

  const connectwallet = async () => {
    const connect = await init();
    setconnected(connect);
  };

  const swap = async () => {
    let hash;
    if (swapOut) {
      hash = await SWAP(outamount, "out");
    } else {
      hash = await SWAP(inamount, "in");
    }
    settxid(hash);
    setTokenMintSuccess(true);
  };

  return (
    <div className="project" id="project">
      <div className="project-container">
        <div className="project-heading">GEMPOINTS (Utility Token)</div>
        <p>
          GEMPOINTS is a utility token which powers the GemTexch Ecosystem, GEMP
          is actively used to trade NFTs from within social media platforms like
          Discord and Telegram.
        </p>

        <div className="contract-address">
          Contract address:
          <a
            href="https://www.teloscan.io/address/0xCcE1700A7B116aCE33A968F25ECc526504990424"
            target={"_blank"}
          >
            0xCcE1700A7B116aCE33A968F25ECc526504990424
          </a>
        </div>
        <div className="project-heading">$GEMP TOKENOMICS</div>
        <div className="project-wrapper">
          <div className="projects">
            <div className="image">
              <img src={one} alt="" />
            </div>
            <h4>100% Liquidity</h4>
            <div className="info">
              100% of every purchase in $TLOS is locked in the smart contract as
              liquidity
            </div>
          </div>
          <div className="projects">
            <div className="image">
              <img src={two} alt="" />
            </div>
            <h4>1% Buy Tax</h4>
            <div className="info">
              1% amount of each swap into $GEMP is sent into the sustainability
              pool which fuels our Inncentive schemes
            </div>
          </div>
          <div className="projects">
            <div className="image">
              <img src={three} alt="" />
            </div>
            <h4>0% Sell Tax</h4>
            <div className="info">
              Converting your GEMP to Telos incures no Tax fee and you get 100%
              conversion amount.
            </div>
          </div>
          <div className="projects">
            <div className="image">
              <img src={four} alt="" />
            </div>
            <h4>Anchored Price</h4>
            <div className="info">
              The Price of GEMP is strictly Anchored to Telos in ratio 1:100.
              GEMP is a <strong>utility</strong> token not an investment token
            </div>
          </div>
        </div>
        <div id="swap-box">
          {tokenmintSuccess ? (
            <div className="overlay">
              <div>
                <i class="fa fa-window-close" onClick={handleTokenClose} />

                <h2>Swap Successful!</h2>
                <span onClick={addToMetamask}>Add to Metamask</span>
                <a href={`https://teloscan.io/tx/${txid}`} target={"_blank"}>
                  <span>View on Teloscan</span>
                </a>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <h2>
                SWAP TLOS <i class="fa fa-arrows-h" /> GEMP
              </h2>
              <div className="swapitem">
                <p>You pay</p> {!swapOut ? swapitem(tlos) : swapitem(gemp)}
              </div>
              <button className="switch" onClick={changeSwap}>
                <i class="fa fa-arrows-v"></i>
              </button>
              <div className="swapitem">
                <p>You get</p>
                {!swapOut ? swapitem(gemp) : swapitem(tlos)}
              </div>
              <div className="swap-action">
                {!connected ? (
                  <div>
                    <button className="modal-btn" onClick={handleOpenModal}>
                      Connect Wallet To Swap
                    </button>
                    {openmodal ? (
                      <div className="overlay">
                        <div>
                          <i
                            class="fa fa-window-close"
                            onClick={handleCloseModal}
                          />
                          <img
                            width={80}
                            src={META}
                            alt="metamask"
                            onClick={connectwallet}
                          />
                          <p>Connect Metamask</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <button onClick={swap}>SWAP</button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Token;
